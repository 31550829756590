<template>
  <v-dialog
    scrollable
    persistent
    no-click-animation
    :value="status.addDialog"
    :max-width="$dialog.content"
  >
    <close-btn @click="close()" overflow />
    <v-card v-if="data && data.role === 'admin' && user.role !== 'admin'">
      <v-card-text class="pa-7">
        <h3>Oops!!! You don't have permission to update this account.</h3>
      </v-card-text>
    </v-card>
    <v-card
      v-else
      :loading="searching"
    >
      <v-card-title>
        <span class="font-weight-bold text-h5">{{ isCollaborator ? 'Add Collaborator' : 'Update User' }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-10">
        <v-alert v-if="status.error" type="error" border="left" class="mb-7">{{ status.error }}</v-alert>

        <v-form ref="userForm" @submit.prevent="validateForm()">
          <div v-if="!isCollaborator">
            <v-text-field
              :rules="[rules.required]"
              v-model="data.fullName"
              label="Full Name"
              outlined
            ></v-text-field>

            <v-text-field
              :disabled="(typeof data.id !== 'undefined')"
              :rules="[rules.required, rules.email]"
              v-model="data.email"
              label="Email"
              type="email"
              outlined
            ></v-text-field>
          </div>

          <div v-if="isCollaborator">
            <preloader v-if="collabStatus.getting" />
            <div v-if="!collabStatus.getting">
              <v-text-field
                append-icon="mdi-account-search-outline"
                @keyup.enter="searching='accent'"
                label="Search user by email"
                hint="Press Enter to search"
                :rules="[rules.email]"
                v-model="search"
                type="text"
                clearable
                rounded
                solo
              ></v-text-field>

              <v-simple-table v-if="users.length" 
                class="elevation-2 pa-5 mb-10 rounded-lg"
                max-height="400px"
                height="400px"
                fixed-header
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th width="286px">User</th>
                      <th width="237px">Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr 
                      v-if="filteredList.length < 1 || filteredList == undefined"
                      v-bind:class="{ 'active': !showInvite }"
                    >
                      <td colspan="100%" class="pt-5 pb-5">
                        <v-alert type="warning" border="left" class="mb-0" text>
                          User doesn't exist. Click
                          <span class="text-success">
                            <v-btn 
                              v-on:click="showInvite = !showInvite"
                              class="gradient mx-1" 
                              color="info"
                              depressed
                              x-small
                            >
                              here
                            </v-btn> to invite.
                          </span>
                        </v-alert>
                      </td>
                    </tr>
                    <tr 
                      v-if="filteredList.length < 1 || filteredList == undefined" 
                      v-bind:class="{ 'd-none': showInvite }"
                      class="invite_tr"
                    >
                    <td colspan="4">
                      <p class="text-center mt-3">Invite someone to be part of your project 
                      <span>
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="addRow()"
                        >
                          <v-icon dark>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </span>
                      </p>
                      <v-row 
                        v-for="(invite, index) in invites" :key="index"
                      >
                        <v-col cols="6" class="py-0">
                          <v-text-field
                            v-on:blur="triggeredEvent = !triggeredEvent"
                            :rules="[rules.required, rules.email]"
                            :ref="`${invite.email}-invite-email`"
                            @keydown="inputInvite(index)"
                            @blur="inputInvite(index)"
                            v-model="invite.email"
                            label="Email"
                            type="email"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="5" class="py-0">
                          <v-select
                            :items="userRoles.inviteCollaborator"
                            :disabled="disableInvite[index]"
                            :ref="index + '-invite-collab'"
                            :rules="[rules.required]"
                            v-model="invite.role"
                            label="Collaborator"
                            hide-details
                            dense
                          ></v-select>
                        </v-col>
                        <v-col 
                        cols="1"
                        v-if="index > 0"
                        class="py-0"
                        >
                          <v-btn
                            @click="removeRow(index)"
                            class="mx-2 remove-btn"
                            color="dark"
                            x-small
                            dark
                            fab
                          >
                            <v-icon dark>
                              mdi-minus
                            </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </td>
                    </tr>
                    <tr 
                      v-for="user in filteredList" :key="user.id"
                      :ref="`${user.userid}-tr`"
                    >
                      <td width="10" class="pr-0">
                        <v-checkbox
                          @change="selectedUser(user.userid)"
                          v-model="collabUsers"
                          :value="user.userid"
                          dense
                        ></v-checkbox>
                      </td>
                      <td>
                        <div class="d-flex align-center">
                          <UserPhoto :size="30" photoSize="thumb" :id="user.userid" class="mr-3" />
                          <div>
                            <div class="font-weight-bold">{{ user.fullName }}</div>
                            <div class="text--disabled">{{ user.email }}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <v-select
                          :rules="collabUsers.includes(user.userid) ? [rules.required] : []"
                          @change="selectCollaborator($event, user.userid)"
                          :disabled="!collabUsers.includes(user.userid)"
                          :items="userRoles.userCollaborator"
                          :ref="user.userid + '-select'"
                          label="Collaborator"
                          hide-details
                          dense
                        ></v-select>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              
            </div>
          </div>

          <!-- USER DROPDOWN LIST -->
          <div v-if="!isCollaborator">
            <v-select
              :items="user && user.role === 'admin' ? ['admin', 'user', 'client'] : ['user', 'client']"
              :rules="[rules.required]"
              v-model="data.role"
              label="Role"
              outlined
            ></v-select>

            <v-card v-if="data.role == 'client' && rPackages.length" class="mb-7" outlined>
              <v-card-text class="pa-3">
                <v-text-field
                  :rules="[(value) => value >= settings.allowedActiveRequests || 'Must be equal or higher than the general value']"
                  :placeholder="settings.allowedActiveRequests"
                  v-model.number="data.allowedActiveRequests"
                  label="Allowed active request"
                  type="number"
                  outlined
                ></v-text-field>
                
                <label class="font-weight-bold mb-4">Select packages</label>
                
                <v-checkbox 
                  v-model="data.requestPackages"
                  v-for="rPackage in rPackages"
                  :label="rPackage.name" 
                  :value="rPackage.id"
                  :key="rPackage.id"
                  hide-details
                  multiple
                  dense
                ></v-checkbox>
              </v-card-text>
            </v-card>

            <div class="mt-n3">
              <v-switch
                v-model="data.organization"
                label="Employee"
                class="mt-0"
                outlined
                dense
                inset
              ></v-switch>
            </div>

            <div v-if="data.organization">
              <v-text-field
                v-model="data.employeeRole"
                label="Employee Role"
                outlined
              ></v-text-field>
              
              <v-text-field
                v-model="data.chatId"
                label="Google Chat User ID"
                outlined
              ></v-text-field>
            </div>
            
            <div v-if="data.organization && data.role !== 'admin'">
              <v-card outlined>
                <v-card-text>
                  <p class="font-weight-bold">Extend Permission</p>

                  <v-switch v-model="data.manageUsers" :false-value="false" :true-value="true" label="Users" class="mt-1" hide-details inset dense></v-switch>
                  <v-switch v-model="data.manageRequests" :false-value="false" :true-value="true" label="Requests" class="mt-1" hide-details inset dense></v-switch>
                  <div v-if="data.manageRequests" class="pl-10">
                    <v-switch v-model="data.manageAllRequests" :false-value="false" :true-value="true" label="All requests" class="mt-1" hide-details inset dense></v-switch>
                    <v-switch v-model="data.manageRequestForm" :false-value="false" :true-value="true" label="Forms" class="mt-1" hide-details inset dense></v-switch>
                    <v-switch v-model="data.manageRequestPackages" :false-value="false" :true-value="true" label="Packages" class="mt-1" hide-details inset dense></v-switch>
                    <v-switch v-model="data.manageRequestCannedResponses" :false-value="false" :true-value="true" label="Canned responses" class="mt-1" hide-details inset dense></v-switch>
                  </div>
                  <v-switch v-model="data.manageProjects" :false-value="false" :true-value="true" label="All Projects" class="mt-1" hide-details inset dense></v-switch>
                  <template v-if="user && user.role === 'admin'">
                    <v-switch v-model="data.manageOrders" :false-value="false" :true-value="true" label="Orders" class="mt-1" hide-details inset dense></v-switch>
                    <v-switch v-model="data.manageBlocks" :false-value="false" :true-value="true" label="Panels" class="mt-1" hide-details inset dense></v-switch>
                    <v-switch v-model="data.manageProjectTemplates" :false-value="false" :true-value="true" label="Project Templates" class="mt-1" hide-details inset dense></v-switch>
                    <v-switch v-model="data.managePageTemplates" :false-value="false" :true-value="true" label="Page Templates" class="mt-1" hide-details inset dense></v-switch>
                  </template>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div class="d-flex gap-5">
          <div v-if="!isCollaborator">
            <v-btn
              :loading="status.adding"
              @click="validateForm()"
              color="primary"
              small
            >Submit</v-btn>
          </div>
          
          <div v-if="!showInvite">
            <v-btn
              @click="validateForm('invite')"
              :loading="collabStatus.adding"
              color="primary"
              small
            >Invite</v-btn>
          </div>
          
          <div v-else-if="isCollaborator">
            <v-btn
              @click="validateForm('collaborator')"
              :loading="collabStatus.adding"
              color="primary"
              small
            >Submit</v-btn>
          </div>

          <v-btn
            @click="close()"
            :disabled="status.adding"
            depressed
            small
          >Cancel</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import rules from '@/rules'
import { omit } from 'lodash'
import db from '@/firebase/init'
import { mapState, mapActions } from 'vuex'

/* -------------------------------------------------------------------------- */
/*     User list dropdown for account and collaborator - default is account   */
/* -------------------------------------------------------------------------- */
export default {
  data() {
    return {
      omit,
      rules,
      search: '',
      userType: null, 
      collabUsers: [],
      collabRoles: [],
      hasError: false,
      showInvite: true,
      searching: false,
      disableInvite: [],
      clearSelect: false,
      triggeredEvent: false,
      invitedExist: {
        email: '',
        exist: false
      },
      invites: [{
        email: '',
        role: ''
      }],
      userRoles: {
        userList: [
          'admin', 'user', 'client'
        ],
        userCollaborator: [
          'guest', 'developer', 'project manager'
        ],
        inviteCollaborator: [
          'guest', 'project manager'
        ]
      },
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      data: state => state.users.data,
      status: state => state.users.status,
      users: state => state.collaborators.users,
      rPackages: state => state.request.packages,
      settings: state => state.generalSettings.settings,
      collabStatus: state => state.collaborators.status,
      isCollaborator: state => state.allProjects.collaborator,
    }),

    filteredList() {
      if(this.searching){
        this.turnOffSearch()
        if(typeof this.users != 'object'){ return [] }
        return this.users.filter(user => {
          return user.fullName.toLowerCase().includes(this.search.toLowerCase())
        })
      }else{
        if(typeof this.users != 'object'){ return [] }
        return this.users
      }
    },

  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'data': {
      handler(user) {
        if (Object.keys(user).length) {
          if (!user.requestPackages || (user.requestPackages && !user.requestPackages.length)) {
            this.data.requestPackages = []

            if (user.requestPackage) {
              this.data.requestPackages.push(user.requestPackage)
            }
          }
        }
      },
      immediate: true
    }
  },


  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('users', [
      'addUser'
    ]),

    ...mapActions('collaborators', [
      'assignUser',
      'inviteUser'
    ]),
    

    close() {
      this.$store.commit('users/setAddDialog', false)
      this.$store.commit('users/setData', {})
      this.$store.commit('allProjects/setDialogUserList', false)
      this.$refs.userForm.reset()
      this.searching = false
      this.showInvite = true
      this.invites = [{
        email: '',
        role: ''
      }]
    },

    turnOffSearch() {
      this.searching = false;
      this.collabUsers = []
      this.collabRoles = []
    },

/* -------------------------------------------------------------------------- **
**                  STORE SELECTED COLLABORATOR AND UPDATE ROLES              **
** -------------------------------------------------------------------------- */
    selectCollaborator(event, userid) {
      if(this.collabRoles.length) {
        let _data = this.collabRoles
        this.collabRoles.forEach(function (collab, _key){
          if(userid === Object.keys(collab)[0]) {
            _data.splice(_key,1)
          }
        })
        this.collabRoles.push({
          [userid] : event
        })
      }
      else
      {
        this.collabRoles.push({
          [userid] : event
        })
      }
    },

/* -----------------------------------------------------------------------------
** TOGGLE HIGHLIGHT TR, REMOVED CHOOSEN COLLABORATOR ROLES, REMOVED USER ID  **
---------------------------------------------------------------------------- */
    selectedUser(value) {
      let _contains = this.collabUsers.includes(value)
      this.$refs[`${value}-tr`][0].classList.add('active')
      if(!_contains){
        this.$refs[`${value}-tr`][0].classList.remove('active')
        this.clearSelect = true;
        this.$refs[`${value}-select`][0].reset()
        let _data = this.collabRoles
        this.collabRoles.forEach(function (userID,k){
          if(value === Object.keys(userID)[0]) {
            _data.splice(k,1)
          } 
        })
      }
    },

    validateForm(type = '') {
      if (this.$refs.userForm.validate()) {  
        if (type == '') {
          this.addUser()
        }
        else if(type == 'collaborator') {
          if(this.collabRoles.length && this.collabUsers.length){
            Promise.all([
              this.assignUser({users: this.collabUsers, roles: this.collabRoles})
            ])
            .then(() => {
              this.close()
            })
          }else{
            this.$store.dispatch('showError', 'Need to select collaborators.', { root: true })
          }
        } 
        else if(type == 'invite') {
          const thisCollab = [];
          this.collabStatus.adding = true
          let invitedData = this.invites.map(async (invites) => {
            let invited = await this.alreadyInvited(invites.email)
            thisCollab.push(invited)
          })
          
          Promise.all(invitedData).then(() => {
            if(!thisCollab.includes(true)){
              this.inviteUser(this.invites)
            }
            this.close()
          })
          
        }
      }
    },

    async alreadyInvited(email) {
      let existingEmail = false
      await db.collection('users')
      .where('email', '==', email)
      .get()
      .then(snapshot => {
        if(snapshot.size){
          this.$refs[`${email}-invite-email`][0]._props.errorMessages.push('Email already exist')
          this.invitedExist.email = email
          this.invitedExist.exist = true
          this.hasError = true
          this.collabStatus.adding = false

          return existingEmail = true
        }
      })
      return existingEmail
      
    },
    
    inputInvite(index) {
      if(this.invitedExist.exist) {
        if(this.invites[index].email == this.invitedExist.email) {
          this.$refs[`${this.invitedExist.email}-invite-email`][0]._props.errorMessages.pop()
        }
      }

      if(this.invites[index].email == '') {
        this.disableInvite[index] = true
        this.$refs[`${index}-invite-collab`][0].reset()
      }else{
        if(rules.email(this.invites[index].email) == 'Must be a valid email address'){
          this.disableInvite[index] = true
          this.$refs[`${index}-invite-collab`][0].reset()
        }else{
          this.disableInvite[index] = false
        }
      }
    },

    addRow() {
      this.invites.push({
        email: '',
        role: ''
      })
    },

    removeRow(index) {
      if(this.invites.length > 1){
        this.invites.splice(index, 1)
      }
    },

  },


  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.$store.dispatch('request/getPackages')
  }

}

</script>

<style lang="scss" scope>
  
.invite_tr:hover { background-color: #fff !important; }
.remove-btn { width: 1.2rem !important; height: 1.2rem !important; }

</style>